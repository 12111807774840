export const CONTACTS = [
  {
    type: "list",
    data: [
      {
        title: "Alma Realty Management",
        description: null,
        address: ["31-10 37th Avenue", "Long Island City, NY 1101"],
        items: [
          {
            label: "Phone",
            value: "718-267-0300",
          },
          {
            label: "After Hours / Emergencies",
            value: "718-267-0328",
          },
        ],
      },
      {
        title: "Taaffe Place Building Superintendents",
        description: null,
        address: null,
        items: [
          {
            label: "Endri",
            value: "646-261-8276",
          },
          {
            label: "Qamil",
            value: "347-671-9643",
          },
        ],
      },
    ],
  },
  {
    type: "list",
    data: [
      {
        title: "79th Precinct",
        description: null,
        address: ["263 Tompkins Avenue", "Brooklyn NY 11216"],
        items: [
          {
            label: "Precinct",
            value: "718-636-6611",
          },
          {
            label: "Community Affairs",
            value: "718-636-6626",
          },
          {
            label: "Crime Prevention",
            value: "718-636-6626 or Email Jerone Boone / jerone.boone@nypd.org",
          },
          {
            label: "Domestic Violence",
            value: "718-636-6624",
          },
          {
            label: "Detective Squad",
            value: "718-636-665,",
          },
          {
            label: "Auxiliary Coordination",
            value: "718-636-6601",
          },
          {
            label: "Youth Coordination Officer",
            value: "718-636-2567",
          },
        ],
      },
    ],
  },
  {
    type: "directive",
    data: [],
  },
  {
    type: "list",
    data: [
      {
        title: "Con Edison",
        description: null,
        address: null,
        items: [
          {
            label: "Electric or Gas Outages, Emergencies 24/7",
            value: "800-752-6633",
          },
          {
            label: "ConEd Telecommunications for the Deaf",
            value: "800-642-2308",
          },
        ],
      },
      {
        title: "National Grid",
        description: "718-643-4050 OR CALL 911",
        address: null,
        items: null,
      },
    ],
  },
  {
    type: "list",
    data: [
      {
        title: "Local Hospitals / Urgent Care",
        description: null,
        address: null,
        items: [
          {
            label: "Brooklyn Hospital",
            value: "121 Dekalb Avenue, Brooklyn, NY 11216",
            items: [
              {
                label: "Phone",
                value: "718-250-8000",
              },
            ],
          },
          {
            label: "Woodhull Hospital",
            value: "760 Broadway, Brooklyn, NY 11206",
            items: [
              {
                label: "Phone",
                value: "718-963-8000",
              },
            ],
          },
          {
            label: "New York Presbyterian Methodist Hospital",
            value: "506 6th Avenue, Brooklyn, NY 11215",
            items: [
              {
                label: "Phone",
                value: "718-780-3000",
              },
            ],
          },
          {
            label: "Brooklyn Hospital",
            value: "504 Myrtle Ave, Brooklyn, NY 11205",
            items: [
              {
                label: "Phone",
                value: "718-568-8449",
              },
              {
                label: "Note",
                value: "They accept Medicare, PPO, Self-Pay, Tricare",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "list",
    data: [
      {
        title: "Food Pantries",
        description:
          "Neighbors who are food insecure Rethink Certified has partnered with us to provide a meal service for our community. To sign up please fill out <a href='https://tinyurl.com/TTAMeals' target='_blank'>this survey</a>.",
        address: null,
        items: null,
      },
    ],
  },
]
