import React, { useState } from "react"
import { Link } from "gatsby"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import TextField from "@material-ui/core/TextField"

import Image from "../components/image"
import SEO from "../components/seo"
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles"

// images
import TTAGraphicMap from "images/tta-graphic_map.png"
import TTABuildingHero from "images/tta-hero_building.png"
import TTAPeopleHero from "images/tta-hero_people.png"
import TTALogoBlack from "images/tta_logo_black.svg"
import TTALogoOrange from "images/tta_logo_orange.svg"
import TTAStreetGraphic from "images/tta-graphic_street-numbers.png"
import Instagram from "images/tta-social-icon_instagram.svg"
import Facebook from "images/tta-social-icon_facebook.svg"
import InstagramIcon from "@material-ui/icons/Instagram"
import Slide from "@material-ui/core/Slide"
import { CONTACTS } from "../data/contacts"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const colors = {
  orange: "#f86800",
  yellow: "#f9f002",
  black: "#000",
  white: "#fff",
}

const GlobalCss = withStyles({
  "@global": {
    h3: {
      fontFamily: "Work Sans",
      fontWeight: "700",
    },
    body1: {
      fontFamily: "Work Sans",
      fontWeight: "400",
      fontSize: "1.3rem",
    },
    body2: {
      fontFamily: "Work Sans",
      fontWeight: "400",
      fontSize: "1rem",
    },
  },
})(() => null)

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      h3: {
        fontFamily: "Work Sans",
        fontWeight: 700,
      },
      body1: {
        fontFamily: "Work Sans",
        fontWeight: "400",
        fontSize: "1.2rem",
      },
    },
    // MuiCssBaseline: {
    //   "@global": {
    //     html: {
    //       WebkitFontSmoothing: "auto",
    //     },
    //     h3: {
    //       fontFamily: "Work Sans",
    //     },
    //   },
    // },
  },
})

const mobileMargin = 5

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: colors.orange,
    textAlign: "center",
    paddingTop: theme.spacing(3),
  },
  section: {
    border: `${theme.spacing(2)}px solid ${colors.orange}`,
    position: "relative",
    borderBottom: 0,
  },
  footer: {
    textAlign: "center",
    border: `${theme.spacing(2)}px solid ${colors.orange}`,
    padding: theme.spacing(3),
    backgroundColor: "#000",
    color: "#fff",
  },
  footerLogo: {
    width: "50%",
    marginBottom: 0,

    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  lineBreak: {
    border: "1px solid #fff",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  innerSection: {
    margin: "0 auto",
    paddingBottom: 0,
    paddingTop: 0,

    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  innerContainer: {
    maxWidth: "80%",
    margin: "0 auto",

    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      flexWrap: "wrap",
    },
  },
  innerWrapper: {
    padding: theme.spacing(mobileMargin),
    width: "100%",
    display: "flex",

    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  ttaMapWrapper: {
    display: "none",
    marginRight: theme.spacing(4),

    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  ttaGraphicContainer: {
    textAlign: "center",
  },
  light: {
    backgroundColor: "#fff",
  },
  dark: {
    backgroundColor: "#000",
  },
  whoCopy: {
    color: "#fff",
  },
  whoHeader: {
    color: colors.orange,
  },
  whoCTALabel: {
    color: colors.orange,
    fontWeight: "bold",
  },
  contactUs: {
    display: "flex",
    marginTop: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  primaryBtnRoot: {
    backgroundColor: colors.orange,
    textTransform: "none",
  },
  secondaryBtnRoot: {
    backgroundColor: colors.yellow,
    color: "#000",
    textTransform: "none",
  },
  heading: {
    fontWeight: "bold",
  },
  contactInfo: {
    textAlign: "left",
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  contactLabel: {
    padding: theme.spacing(1),
    backgroundColor: "yellow",
    color: "#000",
    width: "fit-content",
  },
  boldText: {
    fontWeight: "bold",
  },
  welcomeHeader: {
    // backgroundColor: "yellow",
    fontWeight: "bold",
    color: "#000",
    fontSize: "2rem",
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up("sm")]: {
      fontSize: "3rem",
    },
  },
  welcomeCopy: {
    fontWeight: "bold",
    color: "#000",

    [theme.breakpoints.up("sm")]: {
      color: "#fff",
    },
  },
  heroImageWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0,

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  heroImage: {
    width: "100%",

    height: "100%",
  },
  heroContent: {
    zIndex: 2,
    marginRight: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${TTABuildingHero})`,
      padding: theme.spacing(mobileMargin),
      margin: 0,
    },
  },
  heroImg: {
    width: "100%",
  },
  mapImg: {
    width: "100%",
  },
  heroCopy: {
    zIndex: 2,

    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#fff",
      padding: theme.spacing(mobileMargin),
    },
  },
  headerLogo: {
    width: "100px",
    marginBottom: 0,
  },
  heroImageMobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  icon: {
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    color: "#000",
    borderRadius: "100%",

    "&:first-of-type": {
      marginRight: theme.spacing(2),
    },
  },
  faqAnswer: {
    marginBottom: theme.spacing(2),
  },
  icons: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderTop: "1px solid #999",
    borderBottom: "1px solid #999",

    [theme.breakpoints.up("md")]: {
      margin: 0,
      width: "auto",
      border: 0,
    },
  },
  textField: {
    marginTop: theme.spacing(3),
  },
  dialogPaper: {
    border: `5px solid ${colors.orange}`,
  },
  dialogTitle: {
    backgroundColor: colors.orange,
    color: "#fff",
  },
  primaryBtn: {
    backgroundColor: colors.orange,
  },
  formActions: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  contactUsMsg: {
    marginTop: theme.spacing(2),
  },
  emergencyContactsSection: {
    maxWidth: "80%",
    margin: "0 auto",

    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      flexWrap: "wrap",
    },

    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(mobileMargin),
    },
  },
  emergencySet: {
    borderBottom: "1px solid",
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(6),
    flexDirection: "row",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  itemTitle: {
    fontWeight: "bold",
  },
  subitemLabel: {
    fontWeight: "bold",
  },
  // subitemValue: {
  //   marginLeft: theme.spacing(1),
  // },
  directive: {
    display: "flex",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  directiveText: {
    textTransform: "uppercase",
    width: "50%",
    minWidth: "50%",
    "&:first-of-type": {
      paddingRight: theme.spacing(1),
    },

    [theme.breakpoints.down("md")]: {
      width: "100%",
      minWidth: "100%",
      marginBottom: theme.spacing(2),
    },
  },
  setItem: {
    width: "100%",
    "&:first-of-type": {
      paddingRight: theme.spacing(1),
    },
  },
  items: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
  },
  subitem: {
    flex: "1 1 45%",
    marginTop: theme.spacing(2),

    [theme.breakpoints.down("md")]: {
      flex: "1 1 100%",
    },
  },
  subitemTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
}))

const copy = {
  who:
    "50+ households living in properties owned by Alma Realty have formed a Tenants’ Association (known as the TTA) to help keep pandemic-challenged households in their homes. We have expanded our efforts to address safety and environmental issues as a means to enhance the quality of life. Regarding legal representation, the TTA retains counsel to meticulously assist us in our efforts. We welcome you to learn more about our efforts to improve the lives of tenants living on and around Taaffe Place in Brooklyn, NY.",
}

const faqs = [
  {
    question: "What is the Taaffe Tenants Association?",
    answers: [
      "The TTA is an unincorporated Tenants Association working on behalf of members living in the Alma properties at 213 Taaffe, 232-262 Taaffe, 241 Taaffe, 950 Kent and 275 Classon.",
      "The TTA is funded and administered by its members.",
    ],
  },
  {
    question: "Why should I join?",
    answers: [
      "Individual households are virtually powerless to effect change from a billionaire landlord. The TTA combines our resources, volunteers and focus so that we can work to improve life for all of our households. We’ve fostered a community where neighbors can get to know each other and improve living standards together.",
    ],
  },
  {
    question: "What are you working on?",
    answers: [
      "We’re working to prevent neighbors from being evicted in a pandemic. Some of the work includes, but is not limited to, finding out whether all of our buildings should be rent stabilized. We’re resolving issues such as lead dust, package theft, and gas outages, to name a few. We host secured weekly video meetings for all members, provide legal updates via email and have set up a secured server (more info upon request) to help out members keep current.",
      "Our efforts can be limitless for not only members of our community, but for the city, state, and nation alike.",
      "In order to make a meaningful difference, we need your help.",
    ],
  },
]

const SHOW_SOCIAL_ICONS = false

const ERROR_MESSAGES = {
  name: "You must include your full name",
  email: "You must include your email",
  message: "You must include a message",
}

export default function IndexPage() {
  const classes = useStyles()
  const [formOpen, setFormOpen] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [errors, setErrors] = useState({})
  const [formSubmitted, setFormSubmitted] = useState(false)

  const renderHeader = () => {
    return (
      <div className={classes.header}>
        <img src={TTALogoBlack} className={classes.headerLogo} />
      </div>
    )
  }

  const renderSocialIcons = () => {
    if (SHOW_SOCIAL_ICONS) return null

    return (
      <div className={classes.icons}>
        <div className={classes.icon}>
          <InstagramIcon />
        </div>
        <div className={classes.icon}>
          <InstagramIcon />
        </div>
      </div>
    )
  }

  const renderFooter = () => {
    return (
      <Grid container justify="space-evenly" className={classes.footer}>
        <div>
          <img src={TTALogoOrange} className={classes.footerLogo} />
        </div>

        <div className={classes.contactInfo}>
          <div className={classes.contactLabel}>
            <Typography variant="body1" className={classes.boldText}>
              Contact
            </Typography>
          </div>
          <Typography variant="body1" className={classes.boldText}>
            Taaffe Tenants Association
          </Typography>
          <Typography variant="body1">info@taaffetenants.org</Typography>
        </div>
      </Grid>
    )
  }

  const whoWeAre = () => {
    return (
      <Grid container className={classes.innerContainer}>
        <div className={classes.innerWrapper}>
          <Grid item sm={4} xs={12} className={classes.ttaGraphicContainer}>
            <img src={TTAStreetGraphic} />
          </Grid>
          <Grid item sm={8} xs={12}>
            <Typography variant="h3" className={classes.whoHeader}>
              About the TTA
            </Typography>
            <Typography variant="body1" className={classes.whoCopy}>
              {copy.who}
            </Typography>
            <div className={classes.lineBreak} />
            <Grid container justify="space-between" alignItems="center">
              <Button
                color="primary"
                variant="contained"
                classes={{ root: classes.secondaryBtnRoot }}
                onClick={() => setFormOpen(true)}
              >
                Contact Us
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    )
  }

  const renderAccordion = ({ question, answers }) => {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>{question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            {answers.map(ans => (
              <Typography variant="body1" className={classes.faqAnswer}>
                {ans}
              </Typography>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    )
  }

  const moreInfo = () => {
    return (
      <div className={classes.innerWrapper}>
        <Grid container wrap="nowrap" className={classes.innerContainer}>
          <Grid item xs={12} sm={4} className={classes.ttaMapWrapper}>
            <img src={TTAGraphicMap} className={classes.heroImg} />
          </Grid>
          <Grid item sm={12} md={8}>
            <Typography variant="h3">FAQ</Typography>
            <div>
              {faqs.map((faq, index) => (
                <React.Fragment key={index}>
                  {renderAccordion(faq)}
                </React.Fragment>
              ))}
            </div>
            <Button
              color="primary"
              variant="contained"
              classes={{ root: classes.primaryBtnRoot }}
              className={classes.contactUs}
              onClick={() => setFormOpen(true)}
            >
              Contact Us
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }

  const welcome = () => {
    return (
      <Grid container wrap="nowrap" className={classes.innerContainer}>
        <Grid
          item
          sm={12}
          md={6}
          justify="center"
          className={classes.heroContent}
        >
          <img src={TTAPeopleHero} className={classes.heroImg} />
        </Grid>
        <Grid item sm={12} md={6} className={classes.heroCopy}>
          <Typography variant="h3" className={classes.welcomeHeader}>
            <mark>Our Mission</mark>
          </Typography>
          <Typography variant="body1" className={classes.welcomeCopy}>
            To improve the quality of life for neighbors living in the
            community. By coming together we can ensure that our standards of
            living are protected.
          </Typography>
        </Grid>
        <div className={classes.heroImageWrapper}>
          <img src={TTABuildingHero} className={classes.heroImage} />
        </div>
      </Grid>
    )
  }

  const renderSection = (section, theme) => {
    return (
      <div className={[classes.section, classes[theme]].join(" ")}>
        <div className={classes.innerSection}>{section()}</div>
      </div>
    )
  }

  const handleSubmit = async () => {
    const submitData = { _replyto: email, name: name, message: message }
    const url = "https://formspree.io/f/xzbkodwp"
    const anyErrors = validateForm()

    if (anyErrors) return

    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(submitData),
    })
    const data = await response.json()

    if (data.ok) {
      setFormSubmitted(true)
    }
  }

  const isError = field => {
    return !field
  }

  const validateForm = () => {
    const newErrors = {
      name: isError(name),
      email: isError(email),
      message: isError(message),
    }

    setErrors(newErrors)

    return Object.values(newErrors).filter(Boolean).some(Boolean)
  }

  const renderContactForm = () => {
    return (
      <Dialog
        open={formOpen}
        onClose={() => setFormOpen(false)}
        classes={{ paper: classes.dialogPaper }}
        TransitionComponent={Transition}
      >
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Typography variant="h4">Contact Us</Typography>
          <Typography variant="body2">
            Please fill out this form and we'll be in touch shortly. Thank you!
          </Typography>
          <Typography variant="body2">All fields are required</Typography>
        </DialogTitle>

        <DialogContent>
          <TextField
            label="Full Name"
            variant="outlined"
            fullWidth
            value={name}
            className={classes.textField}
            error={errors["name"]}
            helperText={errors["name"] && ERROR_MESSAGES["name"]}
            onChange={evt => setName(evt.target.value)}
          />
          <TextField
            label="Email Address"
            variant="outlined"
            fullWidth
            error={errors["email"]}
            helperText={errors["email"] && ERROR_MESSAGES["email"]}
            className={classes.textField}
            onChange={evt => setEmail(evt.target.value)}
          />
          <TextField
            label="Message"
            multiline
            rows={3}
            variant="outlined"
            error={errors["message"]}
            helperText={errors["message"] && ERROR_MESSAGES["message"]}
            fullWidth
            className={classes.textField}
            onChange={evt => setMessage(evt.target.value)}
          />
          {formSubmitted ? (
            <Typography variant="body1" className={classes.contactUsMsg}>
              Thanks! We've received your inquiry - we'll be in touch shortly!
            </Typography>
          ) : null}
          <Grid container justify="center" className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              classes={{ containedPrimary: classes.primaryBtn }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }

  const renderContactSet = set => {
    switch (set.type) {
      case "directive":
        return (
          <div className={classes.directive}>
            <Typography variant="body1" className={classes.directiveText}>
              <b>Call 911 to report all emergencies:</b> Fire, Smoke, Fumes,
              Odor of Gas
            </Typography>
            <Typography variant="body1" className={classes.directiveText}>
              <b>Call 311:</b> For Non-Emergencies & Complaints
            </Typography>
          </div>
        )
      case "list":
        return (
          <React.Fragment>
            {set.data.map(item => (
              <div className={classes.setItem}>
                <Typography variant="h4" className={classes.itemTitle}>
                  {item.title}
                </Typography>
                {item.description && (
                  <Typography variant="body1">
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </Typography>
                )}
                {item.address &&
                  item.address.map(add => (
                    <React.Fragment>
                      <Typography variant="body1">{add}</Typography>
                    </React.Fragment>
                  ))}
                <div className={classes.items}>
                  {item.items &&
                    item.items.map(subitem => (
                      <Typography variant="body1" className={classes.subitem}>
                        {subitem.items ? (
                          <React.Fragment>
                            <div className={classes.subitemTitle}>
                              {subitem.label}
                            </div>
                            <div className={classes.subitemValue}>
                              {subitem.value}
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <span className={classes.subitemLabel}>
                              {subitem.label}:{" "}
                            </span>
                            <span className={classes.subitemValue}>
                              {subitem.value}
                            </span>
                          </React.Fragment>
                        )}
                        {subitem.items &&
                          subitem.items.map(subitem_item => (
                            <Typography
                              variant="body1"
                              className={classes.subitem_item}
                            >
                              <span className={classes.subitemLabel}>
                                {subitem_item.label}:
                              </span>
                              <span className={classes.subitemValue}>
                                {subitem_item.value}
                              </span>
                            </Typography>
                          ))}
                      </Typography>
                    ))}
                </div>
              </div>
            ))}
          </React.Fragment>
        )
    }
  }

  const emergencyContacts = () => {
    return (
      <div className={classes.emergencyContactsSection}>
        <Typography variant="h3">Emergency Contacts</Typography>
        {CONTACTS.map((set, index) => (
          <Grid
            container
            justify="space-between"
            wrap="nowrap"
            className={classes.emergencySet}
          >
            {renderContactSet(set)}
          </Grid>
        ))}
      </div>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalCss />
      <SEO title="The Taaffe Tenants Assocation" />
      {renderHeader()}
      {renderSection(welcome, "dark")}
      {renderSection(whoWeAre, "dark")}
      {renderSection(moreInfo, "light")}
      {renderSection(emergencyContacts, "light")}
      {renderFooter()}
      {renderContactForm()}
    </ThemeProvider>
  )
}
